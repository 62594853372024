#profile-card {
    background-color: white;
    border-radius: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
}

#profile-image {
    height: 300px;
    margin-left: calc(50% - 150px);
}

#profile-gradient {
    width: calc(100% - 50px);
    height: 200px;
    position: absolute;
    left: 25px;
    top: 230px;
    background-image: linear-gradient(transparent, black);
}

#profile-gradient-navbarless { 
    width: calc(100% - 50px);
    height: 200px;
    position: absolute;
    left: 25px;
    top: 150px;
    background-image: linear-gradient(transparent, black);
}

#profile-name {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 360px;
    text-align: center;
    color: white;
}

#profile-name-navbarless {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 280px;
    text-align: center;
    color: white;
}

#profile-position {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 400px;
    text-align: center;
    color: white;
}

#profile-position-navbarless {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 320px;
    text-align: center;
    color: white;
}

.profile-header {
    text-align: center;
    margin-top: 25px;
}

.profile-table {
    width: calc(100% - 30px);
    margin-left: 15px;
    table-layout: fixed;
    border-spacing: 0;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
}

.profile-table-header {
    background-color: silver;
}

.profile-table-element {
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
}

.profile-table-odd {
    background-color: white;
}

.profile-table-even {
    background-color: gainsboro;
}

#profile-content {
    padding: 25px;
}

@media (max-width: 768px) {

    #profile-gradient {
        top: 200px;
    }

    #profile-name {
        top: 330px;
    }

    #profile-position {
        top: 370px;
    }

}