.user-player-body {
    width: 100%;
    height: 100px;
    margin-top: 20px;
    background-color: white;
    border-radius: 20px;
}

.user-player-rank-body {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    position: relative;
    bottom: 55px;
    display: inline-block;
    background-color: rgb(230, 230, 230);
    border-radius: 30px;
}

.user-player-rank {
    position: relative;
    top: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.user-player-image {
    height: 100px;
    display: inline-block;
}

.user-player-name {
    margin-left: 25px;
    position: relative;
    bottom: 37.5px;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}

.user-player-name:hover {
    text-decoration: underline;
    cursor: pointer;
}


.user-player-points {
    margin-left: calc(100% - 100px);
    position: relative;
    bottom: 100px;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}

.user-button {
    width: 100%;
    height: 50px;
    margin-top: 25px;
    color: white;
    background-color: darkorange;
    font-size: 18px;
    font-weight: bold;
    border-style: none;
    border-radius: 25px;
}

.user-button:hover {
    background-color: rgb(210, 110, 0);
    cursor: pointer;
}

#user-add {
    width: calc(100% - 50px);
    padding: 25px;
    background-color: white;
    border-radius: 25px;
}

#user-stats {
    margin-top: 25px;
    background-color: white;
    border-radius: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
}

#user-stats-header {
    text-align: center;
}

.user-table {
    width: calc(100% - 30px);
    margin-left: 15px;
    table-layout: fixed;
    border-spacing: 0;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
}

.user-header {
    text-align: center;
    margin-top: 10px;
}

.user-table-header {
    background-color: silver;
}

.user-table-element {
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
}

.user-table-odd {
    background-color: white;
}

.user-table-even {
    background-color: gainsboro;
}

#user-content {
    padding: 25px;
}

@media (max-width: 768px) {

    .user-player-rank-body {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        margin-top: 30px;
        top: 0px;
    }

    .user-player-rank {
        top: 10px;
        font-size: 16px;
    }

    .user-player-image {
        height: 0px;
    }

    .user-player-name {
        margin-left: 15px;
        top: 10px;
        font-size: 16px;
    }

    .user-player-points {
        margin-left: calc(100% - 60px);
        bottom: 30px;
        font-size: 16px;
    }

}