#signup-content {
    height: calc(100vh - 80px);
}

#signup-form {
    width: 270px;
    height: 400px;
    margin-left: calc(50vw - 150px);
    margin-top: calc(50vh - 200px);
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border-radius: 20px;
    position: absolute;
}

#signup-header {
    text-align: center;
    margin-top: 50px;
}

.signup-input {
    width: 260px;
    height: 30px;
    font-size: 18px;
}

#signup-login {
    text-align: right;
    margin-top: 10px;
}

#signup-login-link {
    color: darkorange;
    font-weight: bold;
}

#signup-login-link:hover {
    color: rgb(210, 110, 0);
    cursor: pointer;
}

#signup-submit {
    width: 100%;
    height: 50px;
    margin-top: 25px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: darkorange;
    border-style: none;
    border-radius: 25px;
}

#signup-submit:hover {
    background-color: rgb(210, 110, 0);
    cursor: pointer;
}