@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');

* {
  color: black;
  margin: 0;
  font-family: 'Exo';
}

body {
  background-color: rgb(230, 230, 230);
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}