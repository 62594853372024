#home-join-form {
    width: calc(100% - 50px);
    padding: 25px;
    background-color: white;
    border-radius: 25px;
}

.home-input {
    width: 100%;
    height: 30px;
    font-size: 18px;
}

#home-submit {
    width: 100%;
    height: 40px;
    margin-top: 25px;
    color: white;
    background-color: rgb(60, 60, 60);
    font-size: 18px;
    font-weight: bold;
    border-style: none;
    border-radius: 20px;
}

#home-submit:hover {
    background-color: rgb(90, 90, 90);
    cursor: pointer;
}

#home-content {
    padding: 25px;
}

.home-league {
    width: calc(100% - 30px);
    height: 65px;
    margin-top: 20px;
    padding-top: 35px;
    padding-left: 30px;
    background-color: white;
    font-size: 24px;
    font-weight: bold;
    border-radius: 20px;
}

.home-league:hover {
    background-color: lightgray;
    cursor: pointer;
}

#home-league-header {
    margin-top: 20px;
}

.home-button {
    width: 100%;
    height: 50px;
    margin-top: 25px;
    color: white;
    background-color: darkorange;
    font-size: 18px;
    font-weight: bold;
    border-style: none;
    border-radius: 25px;
}

.home-button:hover {
    background-color: rgb(210, 110, 0);
    cursor: pointer;
}