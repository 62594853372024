.league-user-body {
    width: 100%;
    height: 100px;
    margin-top: 20px;
    background-color: white;
    border-radius: 20px;
}

.league-user-body:hover {
    background-color: lightgray;
    cursor: pointer;
}

.league-user-rank-body {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    position: relative;
    top: 20px;
    display: inline-block;
    background-color: rgb(230, 230, 230);
    border-radius: 30px;
}

.league-user-rank {
    position: relative;
    top: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.league-user-name {
    margin-left: 25px;
    position: relative;
    top: 37.5px;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}

.league-user-points {
    margin-left: calc(100% - 100px);
    position: relative;
    bottom: 25px;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}

#league-content {
    padding: 25px;
}

@media (max-width: 768px) {

    .league-user-rank-body {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        margin-top: 30px;
        top: 0px;
    }

    .league-user-rank {
        top: 10px;
        font-size: 16px;
    }

    .league-user-name {
        margin-left: 15px;
        top: 10px;
        font-size: 16px;
    }

    .league-user-points {
        margin-left: calc(100% - 60px);
        bottom: 30px;
        font-size: 16px;
    }

}