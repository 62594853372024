#layout-navbar {
    width: 100%;
    height: 80px;
    position: fixed;
    background-color: white;
    border-bottom: 2px solid rgb(210, 210, 210);
    z-index: 1;
}

#layout-navbar-logo {
    height: 50px;
    margin-top: 15px;
    margin-left: 15px;
}

.layout-navbar-link {
    margin-left: 30px;
    position: relative;
    bottom: 20px;
    font-weight: bold;
    display: inline;
}

.layout-navbar-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

#layout-logout {
    width: 100px;
    height: 35px;
    margin-left: calc(100% - 115px);
    position: relative;
    bottom: 45px;
    color: white;
    background-color: darkorange;
    font-weight: bold;
    border-style: none;
    border-radius: 5px;
}

#layout-logout:hover {
    background-color: rgb(210, 110, 0);
    cursor: pointer;
}

#layout-buffer {
    height: 80px;
}

#layout-footer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    color: darkgray;
    text-align: center;
}

@media (max-width: 768px) {

    #layout-navbar {
        height: 50px;
    }

    #layout-navbar-logo {
        height: 30px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .layout-navbar-link {
        margin-left: 20px;
        bottom: 10px;
    }

    #layout-logout {
        width: 80px;
        margin-left: calc(100% - 90px);
        bottom: 37.5px;
    }

    #layout-buffer {
        height: 50px;
    }

}